import { template } from "@ember/template-compiler";
import { pageTitle } from 'ember-page-title';
import Nav from './nav';
export default template(`
  {{pageTitle "Administration"}}
  <div class="page">
    <header class="page-header">
      <h1>Administration</h1>
      <div class="page-actions">
      </div>
    </header>

    <main class="page-body">
      <Nav />
      {{yield}}
    </main>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
