
import { importSync as i } from '@embroider/macros';
let d = window.define;
import "@ember-data/adapter/-embroider-implicit-modules.js";
import "@ember-data/debug/-embroider-implicit-modules.js";
import "@ember-data/graph/-embroider-implicit-modules.js";
import "@ember-data/json-api/-embroider-implicit-modules.js";
import "@ember-data/legacy-compat/-embroider-implicit-modules.js";
import "@ember-data/model/-embroider-implicit-modules.js";
import "@ember-data/request/-embroider-implicit-modules.js";
import "@ember-data/request-utils/-embroider-implicit-modules.js";
import "@ember-data/serializer/-embroider-implicit-modules.js";
import "@ember-data/store/-embroider-implicit-modules.js";
import "@ember-data/tracking/-embroider-implicit-modules.js";
import "@warp-drive/core-types/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
