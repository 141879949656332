import 'flatpickr';
import 'flatpickr/dist/l10n';

import Application from '@ember/application';
import loadInitializers from 'ember-load-initializers';
import config from 'pix-admin/config/environment';

import Resolver from './resolver';

class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);

export default App;
