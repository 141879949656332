import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
export default template(`
  <header class="page-header">
    <div>
      <LinkTo @route="authenticated.complementary-certifications.list">Toutes les certifications complémentaires</LinkTo>
      <span class="wire">&nbsp;>&nbsp;</span>
      <h1>{{@complementaryCertificationLabel}}</h1>
    </div>
  </header>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
